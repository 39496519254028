<template>
  <div class="about">
    <div style="height: 80px"></div>
    <banner title="联系我们" subTitle="contact us" />
    <div class="c-width">
      <div class="about-context">
        <h3 class="title">联系我们</h3>
        <h2>contact us</h2>
        <div class="context" v-html="data.context"></div>
        <div class="cf tell-box">
          <div class="map fl" id="map">
            <baidu-map
              :ak="data.map.ak"
              class="map"
              :center="{ lng: data.map.lng, lat: data.map.lat }"
              :zoom="data.map.zoom"
            >
              <bm-marker
                :position="{ lng: data.map.lng, lat: data.map.lat }"
                :dragging="true"
                animation="BMAP_ANIMATION_BOUNCE"
              >
              </bm-marker>
            </baidu-map>
          </div>
          <div class="tell-list fl">
              <div class="tell-item cf" v-for="item in data.tellList" :key="item.id">
                  <img class="fl" :src="item.icon" alt="">
                  <p class="text fl">{{item.text}}</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "@/components/banner";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import data from "../data/data";
export default {
  components: {
    banner,
    BaiduMap,
    BmMarker,
  },
  data() {
    return {
      data: data,
    };
  },
};
</script>
<style scoped>
@import "../style.css";
</style>