export default {
    map:{
        lng: '118.414543',   //经度
        lat: '35.028657',   //纬度
        ak: 'YeGHmrb5Rp5pX05QcePDbnGut3UseaHM',
        zoom: 14,
    },
    context:'<p>山东钾能化工有限公司成立于2015年8月，总部位于中国北方物流之都的山东临沂。成立之初取得青海盐湖集团授权,是青海盐湖集团指定的专业经营代理商，依托盐湖集团“三大基地、五大产业群”的战略布局，主营硝酸钾、氢氧化钾、碳酸钾、氯化钾、尿素、PVC、PP等优势产品及各类化工产品和肥料，公司自成立以来，发展迅速，贸易网络遍及国内24省，出口产品远销东南亚、日韩、欧美和非洲等地。硝酸钾年销售量达6万吨，氢氧化钾年销售量达4万吨。</p><p>公司创立以来，始终秉持品质为上、价格合理、服务专业的理念，以诚信的态度和高效的作风赢得海内外客户的高度评价认可。</p>',
    tellList:[
        {
            id:1,
            text:'电话：0539-8808601',
            icon:require('../../../assets/images/dh_ic.png')
        },{
            id:2,
            text:'邮箱：781306313@qq.com',
            icon:require('../../../assets/images/yx_ic.png')
        },
        {
            id:3,
            text:`微信：郭经理：15265198009 
                  庄经理：15020307350 
                  刘经理：18866952892 
                  刘经理：17806197772`,
            icon:require('../../../assets/images/wx_ic.png')
        }, {
            id:4,
            text:'QQ：781306313',
            icon:require('../../../assets/images/qq_ic.png')
        }, {
            id:5,
            text:'传真：0539-8808601',
            icon:require('../../../assets/images/cz_ic.png')
        }, {
            id:6,
            text:'地址：山东省临沂市经济开发区金科财税大厦A区808室',
            icon:require('../../../assets/images/dz_ic.png')
        },
    ]
}
